import React from 'react';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES } from '@contentful/rich-text-types';
import Layout from '../components/layouts/layout';
import Seo from '../components/seo/seo';

const SimplePage = ({ data }) => {

  const { node_locale, title, bodyText, seoSettings } = data.contentfulSimplePage;
  const seoImage = seoSettings?.image?.gatsbyImageData ? getSrc(seoSettings?.image.gatsbyImageData) : null;

  // Rich text field options
  const options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.target.slug}>{node.content[0].value}</a>
        )
      }
    }
  }

  const text = renderRichText(bodyText);

  return (
    <Layout colorTheme='Green' nodeLocale={node_locale}>
      <Seo
        seoTitle={seoSettings?.seoTitle ? seoSettings?.seoTitle : title}
        seoDesc={seoSettings?.description ? seoSettings?.description.description : null}
        keywords={seoSettings?.keywords ? seoSettings?.keywords : null}
        image={seoImage ? seoImage : null}
        url={typeof window !== 'undefined' ? window.location.href : ''}
        lang={node_locale.substr(0, 2)}
        noIndex={seoSettings?.hidePageFromSearchEnginesNoindex ? 'nofollow' : 'follow'}
        noFollow={seoSettings?.excludeLinksFromSearchRankingsNofollow ? 'noindex' : 'index'}
      />
      <article className="px-4 sm:px-6 md:px-12 lg:px-20 pt-24 pb-2 xs:pt-32 lg:pt-40 lg:pb-10 min-h-screen">
        <div className="max-w-screen-lg mx-auto">
          <div className="md:-mx-6 flex flex-wrap">
            <div className="md:px-6 w-full md:w-4/12 lg:w-3/12">
              <h2 className="mb-6 heading-3-book break-words hyphens-auto--max-width">{title}</h2>
            </div>

            <div className="md:px-6 w-full md:w-8/12 lg:w-9/12">
              {text &&
                <div className="body-text">
                  {text}
                </div>
              }
            </div>
          </div>
        </div>
      </article>
    </Layout >
  )
}

export const pageQuery = graphql`
  query simplePageQuery($slug: String, $locale: String) {
    contentfulSimplePage( slug: {eq: $slug }, node_locale: { eq: $locale }) {
      slug
      node_locale
      title
      bodyText {
        raw
      }
      seoSettings {
        seoTitle
        description {
          description
        }
        keywords
        image {
          gatsbyImageData
        }
        excludeLinksFromSearchRankingsNofollow
        hidePageFromSearchEnginesNoindex
      }
    }
  }
`

export default SimplePage;
